import Btn from '@componentPrimitives/Btn';
import Flex, { FlexProps } from '@componentPrimitives/Flex';
import Icon from '@components/Icon';
import { Link } from '@lib/features/common/linkField';
import React from 'react';

export type LinkListProps<C extends React.ElementType = 'nav'> = FlexProps<
  C,
  {
    items?: Link[];
  }
>;

const LinkList = ({ items = [], ...props }: LinkListProps) => {
  return items.length ? (
    <Flex spacing="3xs" {...props}>
      {items.map(({ customText: _customText, ...item }, i) => (
        <Btn key={i} variant="text" {...item}>
          <Icon name="arrowCircleRight" cx={{ color: 'primary' }} /> {item.children}
        </Btn>
      ))}
    </Flex>
  ) : null;
};

export default LinkList;
