import { BlocksAnchorMenu } from '@components/Blocks';
import { Entry } from '@lib/features/entries';
import { PageHeaderProps } from './PageHeader';
import { PageHeaderRoot } from './PageHeaderRoot';

export const PageHeaderStandard = ({ view }: PageHeaderProps<Entry<'page', 'default'>>) => {
  const { disablePageMenu } = view;
  return (
    <PageHeaderRoot maxWidth="md" cx={{ pY: 'none', mY: '2xl' }}>
      {!disablePageMenu && <BlocksAnchorMenu />}
    </PageHeaderRoot>
  );
};
