import Grid from '@componentPrimitives/Grid';
import Image from '@components/Image/Image';
import { Entry } from '@lib/features/entries';
import { PageHeaderProps } from './PageHeader';
import { PageHeaderRoot } from './PageHeaderRoot';

export const PageHeaderProfile = ({ view }: PageHeaderProps<Entry<'profile'>>) => {
  const { title, entryImage } = view;

  return (
    <PageHeaderRoot
      beforeTitle={
        !!entryImage && (
          <Grid>
            <Grid.Col sm={6} md={4} lg={3}>
              <Image round ratio="square" alt={title || ''} {...entryImage} />
            </Grid.Col>
          </Grid>
        )
      }
    />
  );
};
