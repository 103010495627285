import Image from '@component/Image';
import Container from '@componentPrimitives/Container';
import Grid from '@componentPrimitives/Grid';
import VideoBackground from '@components/Video/VideoBackground';
import { Entry } from '@lib/features/entries';
import Box from '@primitive/Box';
import Txt from '@primitive/Txt';
import { EntryLinks } from './EntryLinks';
import { PageHeaderProps } from './PageHeader';
import * as style from './PageHeaderHomeRtc.css';

export const PageHeaderHomeRtc = ({ view }: PageHeaderProps<Entry<'home'>>) => {
  const {
    title,
    pageTitle = title,
    entrySummary: summary,
    entryImage,
    videoUrl,
    bannerImage,
  } = view;

  const image = bannerImage ?? entryImage;

  return (
    <>
      <Box as="header" className={style.root} colorTheme="green" colorSet="paper" paper>
        <Container className={style.container}>
          <Grid cx={{ alignItems: 'center' }}>
            <Grid.Col className={style.textCol} sm={8} lg={6} xl={5}>
              <Txt variant="xl" as="h1" className={style.title}>
                {pageTitle}
              </Txt>
              <Txt as="p" variant="xl">
                {summary}
              </Txt>
              <EntryLinks view={view} />
            </Grid.Col>
            <Grid.Col lg={6} offsetXl={1}>
              {videoUrl ? (
                <Box className={style.videoContainer}>
                  <VideoBackground videoUrl={videoUrl} />
                </Box>
              ) : (
                <Image
                  alt=""
                  priority
                  ratio="landscapeTall"
                  ShimProps={{ className: style.imageWrap }}
                  {...image}
                />
              )}
            </Grid.Col>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
