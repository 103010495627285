import { isEntry } from '@lib/features/entries';
import { View } from '@lib/fetch/getViewByUri';
import { useView } from '@lib/store';
import { PageHeaderDefault } from './PageHeader.Default';
import { PageHeaderEvent } from './PageHeader.Event';
import { PageHeaderEventIndex } from './PageHeader.EventIndex';
import { PageHeaderHome } from './PageHeader.Home';
import { PageHeaderOverview } from './PageHeader.Overview';
import { PageHeaderStandard } from './PageHeader.Standard';
import { PageHeaderProfile } from './PageHeader.Profile';
import { IS_RTC } from '@lib/constants';
import { PageHeaderHomeRtc } from './PageHeader.HomeRtc';
import { PageHeaderArticle } from './PageHeader.Article';

export type PageHeaderProps<T = View> = {
  view: T;
};

const PageHeader = ({ view: maybeView }: Partial<PageHeaderProps>) => {
  const contextView = useView();
  const view = maybeView || contextView;

  if (!view) return null;

  if (isEntry(view, 'home'))
    return IS_RTC ? <PageHeaderHomeRtc view={view} /> : <PageHeaderHome view={view} />;

  if (
    isEntry(view, 'page', 'overview') ||
    isEntry(view, 'learningIndex') ||
    isEntry(view, 'profileIndex') ||
    isEntry(view, 'articleIndex')
  )
    return <PageHeaderOverview view={view} />;

  if (isEntry(view, 'eventIndex')) return <PageHeaderEventIndex view={view} />;

  if (isEntry(view, 'article')) return <PageHeaderArticle view={view} />;
  if (isEntry(view, 'event')) return <PageHeaderEvent view={view} />;
  if (isEntry(view, 'profile')) return <PageHeaderProfile view={view} />;
  if (isEntry(view, 'page')) return <PageHeaderStandard view={view} />;

  return <PageHeaderDefault view={view} />;
};

export default PageHeader;
