import Box from '@componentPrimitives/Box';
import { VideoProps } from '@components/Video/Video';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import * as style from './VideoBackground.css';
import { VideoPlayer } from './VideoPlayer';

export type VideoBackgroundProps = { lockRatio?: boolean } & VideoProps;

/**
 * Component for displaying a video background.
 * This component should be wrapped in a container with relative or absolute positioning.
 */
const VideoBackground = ({
  lockRatio = false,
  videoUrl,
  controls = false,
  playing = true,
  loop = true,
  muted = true,
}: VideoBackgroundProps) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  // State variable to track if the video has been initialized
  const [initialized, setInitialized] = useState(false);
  const [ratio, setRatio] = useState<number>(16 / 9);

  // const nextHydrated = useNextHydrated();
  const handleOnReady = (player: ReactPlayer) => {
    const maybeInternalPlayer = player.getInternalPlayer();
    if (!maybeInternalPlayer || lockRatio) return;
    Promise.all([maybeInternalPlayer.getVideoWidth(), maybeInternalPlayer.getVideoHeight()]).then(
      function ([width, height]) {
        const ratio = width / height;
        setRatio(ratio);
        // console.log('Set ratio!');
      }
    );
  };

  // Callback function to set the dimensions of the video
  const setDimensions = useCallback(() => {
    // Return if the references are not set
    if (!wrapRef.current || !innerRef.current) return;

    // Get the current inner element and the dimensions of the wrap element
    const inner = innerRef.current;

    // unset the styles
    inner.style.width = '';
    inner.style.height = '';

    const wrapWidth = wrapRef.current.clientWidth;
    const wrapHeight = wrapRef.current.clientHeight;

    // Determine if the wrap element is in landscape orientation
    const isLandscape = wrapWidth / wrapHeight > ratio;

    // Ensure inner is always larger than wrap, and has the correct aspect ratio
    const innerWidth = isLandscape ? wrapWidth : wrapHeight * ratio;
    const innerHeight = isLandscape ? wrapWidth / ratio : wrapHeight;

    // Set the dimensions of the inner element
    inner.style.width = `${innerWidth}px`;
    inner.style.height = `${innerHeight}px`;

    // Set the initialized state to true
    setInitialized(true);
  }, [wrapRef, ratio]); // Dependencies for the useCallback hook

  // Effect hook to add the resize event listener and set the initial dimensions
  useEffect(() => {
    // Add the resize event listener
    window.addEventListener('resize', setDimensions);
    // Set the initial dimensions
    setDimensions();

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', setDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratio]); // Empty dependency array to run the effect only once on mount

  return videoUrl ? (
    <Box ref={wrapRef} className={style.root({ visible: initialized })}>
      <Box ref={innerRef} className={style.inner}>
        <VideoPlayer
          {...{
            onReady: handleOnReady,
            url: videoUrl,
            controls,
            playing,
            loop,
            muted,
            width: '100%',
            height: '100%',
            config: {
              vimeo: {
                playerOptions: {},
              },
            },
          }}
        />
      </Box>
    </Box>
  ) : null;
};
export default VideoBackground;
