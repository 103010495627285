import Container from '@componentPrimitives/Container';
import Grid from '@componentPrimitives/Grid';
import { Entry } from '@lib/features/entries';
import { useGlobalColorTheme } from '@lib/store';
import Box from '@primitive/Box';
import Txt from '@primitive/Txt';
import { PageHeaderProps } from './PageHeader';
import { PageTitle } from './PageTitle';
import Breadcrumbs from '@components/Breadcrumbs';
import { EntrySummary } from './EntrySummary';

export const PageHeaderEventIndex = ({ view }: PageHeaderProps<Entry<'eventIndex'>>) => {
  const globalColorTheme = useGlobalColorTheme();

  return (
    <Box as="header" colorTheme={globalColorTheme} colorSet="paper" paper>
      <Container cx={{ pY: '2xl' }}>
        <Grid>
          <Grid.Col lg={7} cx={{ display: 'flex', spacing: 'lg', flexDirection: 'column' }}>
            <Breadcrumbs />
            <PageTitle view={view} />
            <EntrySummary />
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
};
