import Image from '@component/Image';
import Container from '@componentPrimitives/Container';
import Grid from '@componentPrimitives/Grid';
import LinkList from '@components/LinkList';
import OpenHours from '@components/OpenHours';
import TxtHighlight from '@components/TxtHighlight';
import VideoBackground from '@components/Video/VideoBackground';
import { Entry } from '@lib/features/entries';
import Box from '@primitive/Box';
import Txt from '@primitive/Txt';
import { PageHeaderProps } from './PageHeader';
import * as style from './PageHeaderHome.css';

export const PageHeaderHome = ({ view }: PageHeaderProps<Entry<'home'>>) => {
  const {
    title,
    pageTitle = title,
    entrySummary: summary,
    entryLinks,
    entryImage,
    videoUrl,
    bannerImage,
  } = view;

  const image = bannerImage ?? entryImage;

  return (
    <>
      <Box as="header" className={style.root}>
        <Container className={style.container}>
          <Grid cx={{ alignItems: 'center' }}>
            <Grid.Col sm={8} lg={6} xl={4} className={style.textCol}>
              <TxtHighlight variant="xl" as="h1" className={style.title}>
                {pageTitle}
              </TxtHighlight>
            </Grid.Col>
            <Grid.Col>
              {videoUrl ? (
                <Box className={style.videoContainer}>
                  <VideoBackground videoUrl={videoUrl} />
                </Box>
              ) : (
                <Image
                  alt=""
                  priority
                  fixedRatio
                  ShimProps={{ className: style.imageWrap }}
                  {...image}
                />
              )}
            </Grid.Col>
            <Grid.Col sm={10} lg={4} className={style.textCol}>
              <Txt as="p" variant="large">
                {summary}
              </Txt>
              <LinkList items={entryLinks} alignItems="start" cx={{ mY: 'sm' }} />
            </Grid.Col>
          </Grid>
        </Container>
      </Box>
      <Container>
        <Grid
          cx={{
            alignItems: 'center',
            justifyContent: { lg: 'end' },
          }}>
          <Grid.Col sm={10} lg={6} xl={4}>
            <OpenHours disableCta cx={{ marginY: 'lg' }} />
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};
