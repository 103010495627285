import Btn from '@componentPrimitives/Btn';
import Flex from '@componentPrimitives/Flex';
import { Entry } from '@lib/features/entries';
import { useView } from '@lib/store';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { PageHeaderProps } from './PageHeader';

export const EntryLinks = ({ view: maybeView }: Partial<PageHeaderProps<Entry>>) => {
  const appView = useView();
  const view = maybeView ?? appView;
  const entryLinks = maybeGet(view, 'entryLinks');

  if (!entryLinks?.length) return null;

  return (
    <Flex direction="row" wrap spacing="xs">
      {entryLinks.map((v, i) => (
        <Btn key={i} endIcon="arrowRight" {...v} />
      ))}
    </Flex>
  );
};
