import { Entry } from '@lib/features/entries';
import { useViewColorTheme } from '@lib/store';
import { EntryLinks } from './EntryLinks';
import { EntrySummary } from './EntrySummary';
import { PageHeaderProps } from './PageHeader';
import { PageHeaderRoot } from './PageHeaderRoot';

export const PageHeaderOverview = ({
  view,
}: PageHeaderProps<
  Entry<'page', 'overview'> | Entry<'articleIndex'> | Entry<'learningIndex'> | Entry<'profileIndex'>
>) => {
  const viewColorTheme = useViewColorTheme();

  return (
    <PageHeaderRoot variant="overview" colorTheme={viewColorTheme} colorSet="paper" paper>
      <EntrySummary />
      <EntryLinks view={view} />
    </PageHeaderRoot>
  );
};
