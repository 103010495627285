import Txt, { TxtProps } from '@primitive/Txt';
import classNames from 'classnames';
import React from 'react';
import { useTxtHighlightStyle } from './useTxtHighlightStyle';
import * as style from './TxtHighlight.css';

export type TxtHighlightProps<C extends React.ElementType = 'span'> = Omit<
  TxtProps<C>,
  'children'
> & {
  children?: string | null;
  childClassName?: string;
};

const TxtHighlight = <C extends React.ElementType>({
  children: text = '',
  childClassName,
  ...props
}: TxtHighlightProps<C>) => {
  const words = React.useMemo(() => (text ?? '').split(' '), [text]);

  return (
    <Txt {...useTxtHighlightStyle(props)}>
      {words.map((line, i) => (
        <React.Fragment key={i}>
          <span className={classNames(style.word, childClassName)}>{line}</span>
          {` `}
        </React.Fragment>
      ))}
    </Txt>
  );
};

export default TxtHighlight;
