import Container from '@componentPrimitives/Container';
import Divider from '@componentPrimitives/Divider';
import Flex from '@componentPrimitives/Flex';
import Txt from '@componentPrimitives/Txt';
import Image from '@components/Image';
import { Entry } from '@lib/features/entries';
import { useTranslations } from 'next-intl';
import { PageHeaderProps } from './PageHeader';
import { PageHeaderRoot } from './PageHeaderRoot';

export const PageHeaderArticle = ({ view }: PageHeaderProps<Entry<'article'>>) => {
  const t = useTranslations('common');
  const { entryImage, bannerImage, postDate, author } = view;
  const image = bannerImage || entryImage;
  //
  const showAuthor = false;
  const authorName = author?.displayName ?? null;
  return (
    <>
      <PageHeaderRoot
        maxWidth="md"
        afterContainer={
          image && (
            <Container>
              <Image
                alt={view.title ?? ''}
                ratio="landscapeWide"
                ShimProps={{ cx: { mT: 'lg' } }}
                rounded
                {...image}
              />
            </Container>
          )
        }>
        <Flex direction="row" spacing="xs">
          {showAuthor && authorName && (
            <>
              <Txt variant="large">{t('byAuthor', { author: authorName })}</Txt>
              <Divider direction="vertical" />
            </>
          )}
          <Txt variant="large">{postDate}</Txt>
        </Flex>
      </PageHeaderRoot>
    </>
  );
};
